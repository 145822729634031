const state = {
  drawer: null,
  color: 'primary',
  appName: window.envs.APP_NAME,
  isProductionMode: process.env.NODE_ENV === 'production',
}

const getters = {}

const actions = {}

const mutations = {
  TOGGLE_DRAWER: state => {
    state.drawer = !state.drawer
  },
  SET_DRAWER: (state, status) => {
    state.drawer = status
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
