import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import de from 'vuetify/es5/locale/de'

import Vuetify, { VList, VCol, VTooltip } from 'vuetify/lib'

Vue.use(Vuetify, {
  components: {
    VList,
    VCol,
    VTooltip,
  },
})

const opts = {
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: `#${window.envs.APP_BRAND_PRIMARY}` || '#FF9800',
        secondary: `#${window.envs.APP_BRAND_SECONDARY}` || '#0b63e4',
        tertiary: `#${window.envs.APP_BRAND_TERTIARY}` || '#0ed121',
        accent: '#82B1FF',
        error: '#f55a4e',
        info: '#00d3ee',
        success: '#5cb860',
        warning: '#ffa21a',
      },
      dark: {
        primary: `#${window.envs.APP_BRAND_PRIMARY}` || '#FF9800',
        secondary: `#${window.envs.APP_BRAND_SECONDARY}` || '#0b63e4',
        tertiary: `#${window.envs.APP_BRAND_TERTIARY}` || '#0ed121',
        accent: '#82B1FF',
        error: '#f55a4e',
        info: '#00d3ee',
        success: '#5cb860',
        warning: '#ffa21a',
      },
    },
  },
  lang: {
    locales: { de },
    current: 'de',
  },
}

export default new Vuetify(opts)
