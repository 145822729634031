import http from './http'

export default {
  setStatus(quote) {
    return http.get(`quotes/status/${quote.id}/${quote.status}`)
  },
  getPdf(quoteId) {
    return http.get(`quotes/viewPdf/${quoteId}`)
  },
  getPdfPreview(quote) {
    return http.post('quotes/viewPdf', {
      quote,
    })
  },
}
