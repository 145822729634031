// Product Store

import { getField, updateField } from 'vuex-map-fields'
import { clone } from 'lodash'

const product = {
  id: null,
  number: null,
  categorie_id: null,
  name: null,
  period: 1,
  baseprice: null,
  description: null,
  category: {
    id: null,
    name: null,
  },
  tax: null,
  subscriptionstext: null,
}

const state = {
  product: clone(product),
  pending: false,
  pendingSave: false,
  errors: {},
}

const getters = {
  getField,
}

const actions = {
  getProduct({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta
      const { id } = rootState.route.params

      commit('SET_PENDING', true)

      this.api.item
        .getItem(type, id)
        .then(({ data }) => {
          commit('GET_PRODUCT_SUCCESS', data.product)
          resolve()
        })
        .catch(error => {
          commit('GET_PRODUCT_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  getEditProduct({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta
      const { id } = rootState.route.params

      commit('SET_PENDING', true)

      this.api.item
        .getEditItem(type, id)
        .then(({ data }) => {
          commit('GET_PRODUCT_SUCCESS', data.product)
          resolve()
        })
        .catch(error => {
          commit('GET_PRODUCT_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  createProduct({ commit, state, rootState }) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta

      commit('SET_PENDING', true)

      delete state.product.category

      this.api.item
        .addItem(type, state.product)
        .then(({ data }) => {
          if (data && data.errors) {
            commit('ADD_PRODUCT_FAIL', data.errors)
            return
          }
          commit('ADD_PRODUCT_SUCCESS', data.product)
          resolve(data)
        })
        .catch(error => {
          commit('ADD_PRODUCT_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  editProduct({ commit, state, rootState }) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta

      commit('SET_PENDINGSAVE', true)

      delete state.product.category

      this.api.item
        .editItem(type, state.product)
        .then(({ data }) => {
          if (data && data.errors) {
            commit('EDIT_PRODUCT_FAIL', data.errors)
            return
          }
          commit('EDIT_PRODUCT_SUCCESS', data.product)
          resolve(data)
        })
        .catch(error => {
          commit('EDIT_PRODUCT_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDINGSAVE', false)
        })
    })
  },
  clearProduct({ commit }) {
    commit('CLEAR_PRODUCT')
  },
}

const mutations = {
  CLEAR_PRODUCT(state) {
    state.product = clone(product)
    state.errors = {}
  },
  GET_PRODUCT_SUCCESS(state, product) {
    state.errors = {}
    state.product = product
  },
  GET_PRODUCT_FAIL(state, errors) {
    state.errors = errors
  },
  ADD_PRODUCT_SUCCESS(state, product) {
    state.errors = {}
    state.product = product
  },
  ADD_PRODUCT_FAIL(state, errors) {
    state.errors = errors
  },
  EDIT_PRODUCT_SUCCESS(state, product) {
    state.errors = {}
    state.product = product
  },
  EDIT_PRODUCT_FAIL(state, errors) {
    state.errors = errors
  },
  updateField,
}

const object_types = [
  'pending',
  'pendingSave',
]

object_types.forEach(object_type => {
  mutations['SET_' + object_type.toUpperCase()] = (state, payload) => {
    state[object_type] = payload
  }
})

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
