import http from './http'

export default {
  login(request) {
    // TODO: only for a short time with FormData
    const bodyFormData = new FormData()
    bodyFormData.append('username', request.username)
    bodyFormData.append('password', request.password)
    return http({
      url: 'users/login',
      method: 'post',
      data: bodyFormData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  },
  logout() {
    return http.get('users/logout')
  },
  getSession() {
    return http.get('users/getSession')
  },
}
