import Vue from 'vue'

import { sync } from 'vuex-router-sync'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import VueDarkMode from '@vue-a11y/dark-mode'
import VueCompositionAPI from '@vue/composition-api'
import VueApexCharts from 'vue-apexcharts'

import store from './store'
import router from './router'

import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import { i18n, setI18nLanguage } from './plugins/i18n'

import App from './App'

sync(store, router)

Vue.prototype.VUE_APP_VERSION = require('../package.json').version

const tracingOrigins = [
  window.envs.APP_API_URL,
  window.envs.APP_URL,
  /^\//,
]

// if (process.env.NODE_ENV !== 'production') {
//   tracingOrigins.push([
//     'devwi.local',
//     'localhost',
//   ])
// }


Sentry.init({
  Vue,
  environment: process.env.NODE_ENV,
  dsn: window.envs.APP_SENTRY_DSN,
  release: 'msp-cockpit@' + Vue.prototype.VUE_APP_VERSION,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins,
    }),
  ],
  // debug: process.env.NODE_ENV !== 'production',
  logErrors: true,
  attachProps: true,
  attachStacktrace: true,
  tracingOptions: {
    trackComponents: true,
  },
  tracesSampleRate: process.env.NODE_ENV !== 'production' ? 1.0 : 0.2,
  beforeSend(event, hint) {
    if (window.envs.APP_SENTRY_DSN) {
      if (event.exception) {
        Sentry.showReportDialog({ eventId: event.event_id })
      }
    }
    return event
  },
})

Vue.use(require('vue-moment'))
Vue.use(VueLodash, { lodash: lodash })
Vue.use(VueDarkMode)
Vue.use(VueCompositionAPI)
Vue.use(VueApexCharts)

Vue.component('Apexchart', VueApexCharts)

Vue.config.productionTip = false

Vue.prototype.setI18nLanguage = setI18nLanguage

store.dispatch('auth/initAuth')

new Vue({
  el: '#app',
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
})
