import http from './http'

export default {
  addZone(name, ip, template, connection) {
    return http.post('zones/createZone', {
      name,
      ip,
      template,
      connection,
    })
  },
  resetZone(id) {
    return http.delete(`zones/zoneReset/${id}`)
  },
  deleteZone(id) {
    return http.delete(`zones/delete/${id}`)
  },
  deleteRecord(id) {
    return http.delete(`zones/deleteRecord/${id}`)
  },
  getConnections() {
    return http.get('zones/getConnections')
  },
  getTemplates() {
    return http.get('zones/getTemplates')
  },
}
