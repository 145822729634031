<template>
  <ItemWrapper>
    <v-row justify="center">
      <v-col cols="12" sm="8" md="6" lg="4">
        <AppCard
          :loading="pending.getProfile"
          :title="$t('Profile')"
          card-text-classes="text-center"
          :prev-route="prevRoute"
        >
          <template #titleActions>
            <!-- TODO: add edit mode -->
            <!-- <v-btn
              :to="{ name: 'users-edit', params: { id: user.id } }"
              color="success"
              fab
              small
              dark
              class="mr-2"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn> -->
          </template>
          <template v-if="!pending.getProfile">
            <v-avatar class="mx-auto d-block mb-4" size="130">
              <v-img src="https://picsum.photos/200/200" :alt="profile.avatar" />
            </v-avatar>

            <h5 class="text-h6 category text-gray font-weight-light mb-3">
              {{ profile.role }}
            </h5>
            <h3 class="card-title font-weight-light mb-3">
              {{ profile.firstname }} {{ profile.lastname }}
              <v-icon v-if="!profile.active">mdi-account-lock</v-icon>
            </h3>
            <div class="text--disabled">
              <p>
                <b>Username</b><br />
                <span>{{ profile.username }}</span>
              </p>
              <p>
                <b>E-Mail</b><br />
                <span>{{ profile.email }}</span>
              </p>
              <p v-if="profile.client">
                <b>Client</b><br />
                <span>
                  <router-link
                    :to="{
                      name: 'clients-item',
                      params: { id: profile.client.contact_handle.clients_id },
                    }"
                  >
                    <b v-if="profile.client.contact_handle.organisation">
                      {{ profile.client.contact_handle.organisation }}
                    </b>
                    {{ profile.client.contact_handle.firstname }}
                    {{ profile.client.contact_handle.lastname }}
                  </router-link>
                </span>
              </p>
              <!-- <td>{{ user.id }}</td> -->
              <!-- <td>{{ user.client_id }}</td> -->
              <!-- <td>{{ user.newsletter }}</td> -->
              <!-- <td>{{ user.tokencreated }}</td> -->
              <!-- <v-btn color="success" class="font-weight-light">Follow</v-btn> -->
            </div>
          </template>
        </AppCard>
      </v-col>
    </v-row>
  </ItemWrapper>
</template>

<script>
import { defineComponent, onBeforeUnmount, computed, ref } from '@vue/composition-api'

import AppCard from '@/components/UI/AppCard'
import ItemWrapper from '@/layouts/ItemWrapper'

export default defineComponent({
  name: 'ProfilePage',
  components: {
    AppCard,
    ItemWrapper,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // vm.prevRoute = from.fullPath === '/' ? vm.prevRoute : from.fullPath
    })
  },
  setup(props, { root: { $store } }) {
    const prevRoute = ref('/')

    // store
    const pending = computed(_ => $store.state.auth.pending)
    const session = computed(_ => $store.state.auth.session)
    const profile = computed(_ => $store.state.auth.profile)
    const getProfile = _ => $store.dispatch('auth/getProfile')
    const clearProfile = _ => $store.commit('auth/CLEAR_PROFILE')

    getProfile()

    onBeforeUnmount(_ => {
      clearProfile()
    })

    return {
      prevRoute,
      pending,
      session,
      profile,
    }
  },
}) //
</script>

