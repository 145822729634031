// User Store

import { clone } from 'lodash'
import { getField, updateField } from 'vuex-map-fields'

const user = {
  id: null,
  client_id: null,
  role: null,
  firstname: null,
  lastname: null,
  username: null,
  password: null,
  email: null,
  avatar: null,
  newsletter: null,
  tokencreated: null,
  active: null,
}

const state = {
  user: clone(user),
  pending: false,
  pendingSave: false,
  errors: {},
}

const getters = {
  getField,
}

const actions = {
  getUser({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta
      const { id } = rootState.route.params

      commit('SET_PENDING', true)

      this.api.item
        .getItem(type, id)
        .then(({ data }) => {
          commit('GET_USER_SUCCESS', data.user)
          resolve()
        })
        .catch(error => {
          commit('GET_USER_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  getEditUser({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta
      const { id } = rootState.route.params

      commit('SET_PENDING', true)

      this.api.item
        .getEditItem(type, id)
        .then(({ data }) => {
          commit('GET_USER_SUCCESS', data.user)
          resolve()
        })
        .catch(error => {
          commit('GET_USER_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  createUser({ commit, state, rootState }) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta

      commit('SET_PENDING', true)

      this.api.item
        .addItem(type, state.user)
        .then(({ data }) => {
          if (data && data.errors) {
            commit('ADD_USER_FAIL', data.errors)
            return
          }
          commit('ADD_USER_SUCCESS', data)
          resolve(data)
        })
        .catch(error => {
          commit('ADD_USER_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  editUser({ commit, state, rootState }) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta

      commit('SET_PENDINGSAVE', true)

      delete state.user.client

      this.api.item
        .editItem(type, state.user)
        .then(({ data }) => {
          if (data && data.errors) {
            commit('EDIT_USER_FAIL', data.errors)
            return
          }
          commit('EDIT_USER_SUCCESS', data.user)
          resolve(data)
        })
        .catch(error => {
          commit('EDIT_USER_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDINGSAVE', false)
        })
    })
  },
  clearUser({ commit }) {
    commit('CLEAR_USER')
  },
}

const mutations = {
  CLEAR_USER(state) {
    state.user = clone(user)
    state.errors = {}
  },
  GET_USER_SUCCESS(state, user) {
    state.errors = {}
    state.user = user
  },
  GET_USER_FAIL(state, errors) {
    state.errors = errors
  },
  ADD_USER_SUCCESS(state) {
    state.errors = {}
  },
  ADD_USER_FAIL(state, errors) {
    state.errors = errors
  },
  EDIT_USER_SUCCESS(state, user) {
    state.errors = {}
  },
  EDIT_USER_FAIL(state, errors) {
    state.errors = errors
  },
  updateField,
}

const object_types = [
  'pending',
  'pendingSave',
]

object_types.forEach(object_type => {
  mutations['SET_' + object_type.toUpperCase()] = (state, payload) => {
    state[object_type] = payload
  }
})

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
