// Handle Store

import { getField, updateField } from 'vuex-map-fields'
import { clone } from 'lodash'

const handle = {
  clients_id: null,
  sex: null,
  firstname: null,
  lastname: null,
  organisation: null,
  street: null,
  number: null,
  zip: null,
  country: null,
  city: null,
  phone: null,
  fax: null,
  email: null,
  unique_name: null,
  client: {
    contact_handle: {},
  },
}

const state = {
  handle: clone(handle),
  pending: false,
  pendingSave: false,
  foundHandles: [],
  errors: {},
}

const getters = {
  getField,
}

const actions = {
  getHandle({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta
      const { id } = rootState.route.params

      commit('SET_PENDING', true)

      this.api.item
        .getItem(type, id)
        .then(({ data }) => {
          commit('GET_HANDLE_SUCCESS', data.handle)
          resolve()
        })
        .catch(error => {
          commit('GET_HANDLE_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  getEditHandle({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta
      const { id } = rootState.route.params

      commit('SET_PENDING', true)

      this.api.item
        .getEditItem(type, id)
        .then(({ data }) => {
          commit('GET_HANDLE_SUCCESS', data.handle)
          resolve()
        })
        .catch(error => {
          commit('GET_HANDLE_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  createHandle({ commit, state }, { type = null }) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDING', true)

      delete state.handle.client

      const handle = { ...state.handle }
      if (type) {
        handle.type = type
      }

      this.api.item
        .addItem('handles', handle)
        .then(({ data }) => {
          if (data && data.errors) {
            commit('ADD_HANDLE_FAIL', data.errors)
            resolve()
          }
          commit('ADD_HANDLE_SUCCESS', data.handle)
          resolve(data)
        })
        .catch(error => {
          commit('ADD_HANDLE_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  editHandle({ commit, state, rootState }) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta
      commit('SET_PENDINGSAVE', true)

      delete state.handle.client

      this.api.item
        .editItem(type, state.handle)
        .then(({ data }) => {
          if (data && data.errors) {
            commit('EDIT_HANDLE_FAIL', data.errors)
            resolve()
          }
          commit('EDIT_HANDLE_SUCCESS', data.handle)
          resolve(data)
        })
        .catch(error => {
          commit('EDIT_HANDLE_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDINGSAVE', false)
        })
    })
  },
  searchHandle({ commit }, searchString) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDING', true)

      this.api.handle
        .searchHandle(searchString)
        .then(({ data }) => {
          commit('SEARCH_HANDLE_SUCCESS', data)
          resolve(data)
        })
        .catch(error => {
          commit('SEARCH_HANDLE_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  clearHandle({ commit }) {
    commit('CLEAR_HANDLE')
  },
  clearFoundHandles({ commit }) {
    commit('CLEAR_FOUND_HANDLES')
  },
}

const mutations = {
  CLEAR_HANDLE(state) {
    state.handle = clone(handle)
    state.errors = {}
  },
  CLEAR_FOUND_HANDLES(state) {
    state.foundHandles = []
    state.errors = {}
  },
  GET_HANDLE_SUCCESS(state, handle) {
    state.errors = {}
    state.handle = handle
  },
  GET_HANDLE_FAIL(state, errors) {
    state.errors = errors
  },
  ADD_HANDLE_SUCCESS(state, handle) {
    state.errors = {}
    state.handle = handle
  },
  ADD_HANDLE_FAIL(state, errors) {
    state.errors = errors
  },
  EDIT_HANDLE_SUCCESS(state, handle) {
    state.errors = {}
    state.handle = handle
  },
  EDIT_HANDLE_FAIL(state, errors) {
    state.errors = errors
  },
  SEARCH_HANDLE_SUCCESS(state, data) {
    state.errors = {}
    if (data !== null) {
      state.foundHandles = data.handle
    } else {
      state.foundHandles = []
    }
  },
  SEARCH_HANDLE_FAIL(state, errors) {
    state.errors = errors
  },
  ADD_HANDLE_TO_SEARCH(state, handle) {
    state.foundHandles.push(handle)
  },
  updateField,
}

const object_types = [
  'pending',
  'pendingSave',
]

object_types.forEach(object_type => {
  mutations['SET_' + object_type.toUpperCase()] = (state, payload) => {
    state[object_type] = payload
  }
})

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
