<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4" lg3>
        <h1 class="text-h3 text-center">{{ appName }}</h1>
        <AppCard :loading="pending.default" title="Login">
          <v-form @submit.prevent="onLoginSubmit">
            <v-card-text>
              <v-text-field v-model="username" label="Login" name="login" prepend-icon="mdi-account" type="text" />

              <v-text-field
                id="password"
                v-model="password"
                label="Password"
                name="password"
                prepend-icon="mdi-lock"
                type="password"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn type="submit" color="success">Login</v-btn>
            </v-card-actions>
            <p v-if="errors" class="text-danger">
              {{ errors.msg }}
            </p>
          </v-form>
        </AppCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { computed, defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'Login',
  components: {
    AppCard: _ => import('@/components/UI/AppCard'),
  },
  setup(props, { root: { $store, $route, $router } }) {
    const username = ref('')
    const password = ref('')

    // store
    const appName = computed(_ => $store.state.app.appName)
    const errors = computed(_ => $store.state.auth.errors)
    const pending = computed(_ => $store.state.auth.pending)
    const login = payload => $store.dispatch('auth/login', payload)
    const addToast = toast => $store.commit('notifications/ADD_TOAST', toast)

    const onLoginSubmit = _ => {
      if (pending.value.default) {
        return
      }

      login({
        username: username.value,
        password: password.value,
      })
        .then(_ => {
          addToast({
            msg: 'Erfolgreich eingeloggt',
            type: 'success',
          })
          if ($route.query.redirect) {
            $router.push({ path: $route.query.redirect })
          } else {
            $router.push({ name: 'dashboard' })
          }
        })
        .catch(err => {
          addToast({
            msg: 'Login fehlgeschlagen',
            type: 'error',
          })
        })
    }

    return {
      username,
      password,
      errors,
      pending,
      appName,
      onLoginSubmit,
    }
  },
}) //
</script>


<style>
body {
  background-color: #ccc;
}
</style>
