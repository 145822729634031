<template>
  <VueDropzone
    :id="id"
    ref="dropzoneEle"
    :options="config"
    :duplicate-check="duplicateCheck"
    @vdropzone-complete="onPickFile"
    @vdropzone-max-files-exceeded="removeFile"
    @vdropzone-removed-file="onRemoveFile"
    @vdropzone-sending="sendImages"
    @vdropzone-processing="dropzoneChangeUrl"
  />
</template>

<script>
import { defineComponent, ref, watch } from '@vue/composition-api'

import VueDropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default defineComponent({
  name: 'AppDropzone',
  components: {
    VueDropzone,
  },
  props: {
    bearerToken: {
      type: String,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
    duplicateCheck: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: 'uploader',
    },
    // placeholder: {
    //   default: 'Belege hochladen',
    //   type: String,
    // },
    uploadUrl: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const files = ref([])
    const dropzoneEle = ref(null)

    watch(files, _ => {
      emit('onFilesChanged', files)
    })

    const dropzoneChangeUrl = _ => {
      dropzoneEle.value.setOption('url', `${window.envs.APP_API_URL}${props.uploadUrl}`)
    }
    const sendImages = (file, xhr, formData) => {
      xhr.setRequestHeader('Authorization', `Bearer ${props.bearerToken}`)
    }
    const onPickFile = resp => {
      if (resp.status !== 'success') return
      files.value.push(JSON.parse(resp.xhr.response).upload)
      // if (props.config.maxFiles <= files.value.length) dropzoneEle.value.disable()
      // emit('onFilesChanged', files.value)
    }
    const onRemoveFile = file => {
      if (file.status !== 'success') return
      const upload = JSON.parse(file.xhr.response).upload
      files.value.splice(files.value.indexOf(upload), 1)
      // if (props.config.maxFiles > files.value.length) dropzoneEle.value.enable()
      // emit('onFilesChanged', files.value)
    }
    const removeFile = file => {
      dropzoneEle.value.removeFile(file)
    }

    return {
      dropzoneEle,
      dropzoneChangeUrl,
      sendImages,
      onPickFile,
      onRemoveFile,
      removeFile,
    }
  },
})
</script>
