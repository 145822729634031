<template>
  <apexchart :options="options" :series="series" />
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'LineChart',
  setup(props, { root: { $vuetify } }) {
    const options = {
      chart: {
        height: 350,
        type: 'line',
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: .2,
        },
        toolbar: {
          show: true,
        },
      },
      colors: [
        $vuetify.theme.themes.light.primary,
        $vuetify.theme.themes.light.secondary,
      ],
      stroke: {
        width: [
          4,
          3,
        ],
        curve: 'straight',
      },
      markers: {
        size: [
          6,
          3,
        ],
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        strokeDashArray: 3,
      },
      xaxis: {
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
        ],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
    }

    const series = [
      {
        name: 'Comments',
        data: [
          120,
          90,
          130,
          140,
          49,
          62,
          100,
          91,
          148,
        ],
      },
      {
        name: 'Avg Comments per day',
        data: [
          120,
          120,
          110,
          110,
          35,
          90,
          130,
          80,
          100,
        ],
      },
    ]


    return {
      options,
      series,
    }
  },
})
</script>
