<template>
  <v-container class="fill-height error-page" fluid>
    <v-row align-center justify-center class="text-center">
      <v-col auto class="mb-5">
        <h1 class="title">500</h1>
        <h2 class="display-3 mb-5 mt-0">Something went wrong</h2>
        <div class="text-center">
          <h3 class="text-h5"><i class="fa fa-warning text-yellow" />Oops! Try again or contact us.</h3>
          <p>Meanwhile, you may <router-link :to="'/'">return to dashboard</router-link>.</p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'View500',
  setup(props, { root: { $store } }) {
    document.title = `500 Error | ${$store.state.app.appName}`
  },
}) //
</script>
