<template>
  <apexchart :options="options" :series="series" />
</template>

<script>
import { defineComponent } from '@vue/composition-api'


export default defineComponent({
  name: 'PieChart',
  setup() {
    const options = {
      chart: {
        height: 350,
        type: 'pie',
      },
      labels: [
        'Team A',
        'Team B',
        'Team C',
        'Team D',
        'Team E',
      ],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    }

    const series = [
      120,
      90,
      130,
      140,
      49,
    ]

    return {
      options,
      series,
    }
  },
})
</script>
