const state = {
  zones: [],
  pagination: {},
  pending: false,
  errors: {},
}

const getters = {}

const actions = {
  getZones({ commit, rootState }, page) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta

      commit('SET_PENDING', true)

      this.api.items
        .getItems(type, { page })
        .then(({ data }) => {
          commit('GET_ZONES_SUCCESS', data)
          resolve()
        })
        .catch(error => {
          commit('GET_ZONES_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  removeZone({ commit, rootState }, id) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta

      commit('SET_PENDING', true)

      this.api.item
        .removeItem(type, id)
        .then(({ data }) => {
          if (data && data.errors) {
            commit('REMOVE_ZONE_FAIL', data.errors)
            resolve()
          }
          commit('REMOVE_ZONE_SUCCESS', data)
          resolve(data)
        })
        .catch(error => {
          commit('REMOVE_ZONE_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
}

const mutations = {
  SET_PENDING(state, val) {
    state.pending = val
  },
  GET_ZONES_SUCCESS(state, { pagination, zones }) {
    state.errors = {}
    state.zones = zones
    state.pagination = pagination
  },
  GET_ZONES_FAIL(state, errors) {
    state.errors = errors
  },
  REMOVE_ZONE_SUCCESS(state) {
    state.errors = {}
  },
  REMOVE_ZONE_FAIL(state, errors) {
    state.errors = errors
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
