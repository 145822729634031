import { getField, updateField } from 'vuex-map-fields'
import { cloneDeep } from 'lodash'

const ticket = {
  // id: 1,
  // ticket_unique_id: '1',
  subject: null,
  type: null,
  priority: null,
  message: null,
  duedate: null,
  waituntil: null,
  status: null,
  handle_id: null,
  ticket_queue_id: null,
  // creator_id: '1',
  user_id: null,
  // created: '2021-09-25 21:53:21',
  // lastmodified: null,
  // tenant_id: 1,
  // user: null,
  mood: null,
  ticket_tasks: [],
  //   {
  //     message: 'Test Eintrag',
  //     from_date: '2021-10-22 12:05',
  //     to_date: '2021-10-22 12:05',
  //     approach: 0,
  //     accounting: 0,
  //     user_id: null,
  //   },
  // ],
}

const state = {
  ticket: cloneDeep(ticket),
  clientData: null,
  ticketPresets: {},
  pending: {},
  errors: {},
}

const getters = {
  getField,
}

const actions = {
  getTicketPresets({ commit }) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDING', { pending: true, type: 'ticketPresets' })

      this.api.item
        .getOptions('tickets')
        .then(({ data }) => {
          commit('GET_TICKET_PRESETS_SUCCESS', data)
          resolve()
        })
        .catch(error => {
          commit('GET_TICKET_PRESETS_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', { pending: false, type: 'ticketPresets' })
        })
    })
  },

  createTicket({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDING', { pending: true, type: 'createTicket' })

      this.api.item
        .addItem('tickets', state.ticket)
        .then(({ data }) => {
          if (data && data.errors) {
            commit('ADD_TICKET_FAIL', data.errors)
            return
          }
          commit('ADD_TICKET_SUCCESS')
          resolve(data)
        })
        .catch(error => {
          commit('ADD_TICKET_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', { pending: false, type: 'createTicket' })
        })
    })
  },

  getEditTicket({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      const { id } = rootState.route.params

      commit('SET_PENDING', { pending: true, type: 'getEditTicket' })

      this.api.item
        .getEditItem('tickets', id)
        .then(({ data }) => {
          commit('GET_TICKET_SUCCESS', data.ticket)

          delete data.ticket

          commit('GET_TICKET_PRESETS_SUCCESS', data)
          resolve()
        })
        .catch(error => {
          commit('GET_TICKET_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', { pending: false, type: 'getEditTicket' })
        })
    })
  },

  getClientData({ commit }, clientId) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDING', { pending: true, type: 'getClientData' })

      this.api.ticket
        .getClientData(clientId)
        .then(({ data }) => {
          commit('GET_CLIENT_DATA_SUCCESS', data)
          resolve()
        })
        .catch(error => {
          commit('GET_CLIENT_DATA_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', { pending: false, type: 'getClientData' })
        })
    })
  },

  editTicket({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDING', { pending: true, type: 'editTicket' })

      delete state.ticket.user
      delete state.ticket.handle
      delete state.ticket.ticket_queue
      delete state.ticket.client_contacts

      this.api.item
        .editItem('tickets', state.ticket)
        .then(({ data }) => {
          if (data && data.errors) {
            commit('EDIT_TICKET_FAIL', data.errors)
            return
          }
          commit('EDIT_TICKET_SUCCESS')
          resolve(data)
        })
        .catch(error => {
          commit('EDIT_TICKET_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', { pending: false, type: 'editTicket' })
        })
    })
  },

  clearTicket({ commit }) {
    commit('CLEAR_TICKET')
  },
}

const mutations = {
  updateField,
  SET_PENDING: (state, { pending, type = 'default' }) => {
    state.pending = { ...state.pending, [type]: pending }
  },
  CLEAR_TICKET(state) {
    state.ticket = cloneDeep(ticket)
    state.clientData = {}
    state.ticketPresets = {}
    state.errors = {}
  },
  ADD_TICKET_TASK(state) {
    state.ticket.ticket_tasks.push({
      message: null,
      from_date: null,
      to_date: null,
      approach: null,
      ticket_summary_id: null,
      accounting: 1, // default is checked
      user_id: Object.keys(state.ticketPresets.users)[0],
    })
  },
  REMOVE_TICKET_TASK(state, index) {
    state.ticket.ticket_tasks.splice(index, 1)
  },
  UPDATE_TICKET_TASK(state, { index, task }) {
    state.ticket.ticket_tasks[index] = { ...task }
  },
  ADD_TICKET_SUCCESS(state) {
    state.errors = {}
  },
  ADD_TICKET_FAIL(state, errors) {
    state.errors = errors
  },
  GET_TICKET_PRESETS_SUCCESS(state, data) {
    state.ticketPresets = data
    state.errors = {}
  },
  GET_TICKET_PRESETS_FAIL(state, errors) {
    state.errors = errors
  },
  GET_TICKET_SUCCESS(state, data) {
    state.ticket = data
    state.errors = {}
  },
  GET_TICKET_FAIL(state, errors) {
    state.errors = errors
  },
  GET_CLIENT_DATA_SUCCESS(state, data) {
    state.clientData = data
    state.errors = {}
  },
  GET_CLIENT_DATA_FAIL(state, errors) {
    state.errors = errors
  },
  EDIT_TICKET_SUCCESS(state) {
    state.errors = {}
  },
  EDIT_TICKET_FAIL(state, errors) {
    state.errors = errors
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
