import { getField, updateField } from 'vuex-map-fields'
import { cloneDeep } from 'lodash'

const ticketQueue = {
  // id: 1,
  name: null,
  status: null,
  user_id: null,
  // user: {},
  tickets: [],
  users: [],
}

const state = {
  ticketQueue: cloneDeep(ticketQueue),
  ticketQueuePresets: {},
  pending: {},
  errors: {},
}

const getters = {
  getField,
}

const actions = {
  getTicketQueuePresets({ commit }) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDING', { pending: true, type: 'getTicketQueuePresets' })

      this.api.item
        .getOptions('ticketQueues')
        .then(({ data }) => {
          commit('GET_TICKET_QUEUE_PRESETS_SUCCESS', data)
          resolve()
        })
        .catch(error => {
          commit('GET_TICKET_QUEUE_PRESETS_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', { pending: false, type: 'getTicketQueuePresets' })
        })
    })
  },

  createTicketQueue({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDING', { pending: true, type: 'createTicketQueue' })

      this.api.item
        .addItem('ticketQueues', state.ticketQueue)
        .then(({ data }) => {
          if (data && data.errors) {
            commit('ADD_TICKET_QUEUE_FAIL', data.errors)
            return
          }
          commit('ADD_TICKET_QUEUE_SUCCESS')
          resolve(data)
        })
        .catch(error => {
          commit('ADD_TICKET_QUEUE_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', { pending: false, type: 'createTicketQueue' })
        })
    })
  },

  getTicketQueue({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      const { id } = rootState.route.params

      commit('SET_PENDING', { pending: true, type: 'getTicketQueue' })

      this.api.item
        .getItem('ticketQueues', id)
        .then(({ data }) => {
          commit('GET_TICKET_QUEUE_SUCCESS', data.ticketQueue)

          // commit('GET_TICKET_QUEUE_PRESETS_SUCCESS', data)
          resolve()
        })
        .catch(error => {
          commit('GET_TICKET_QUEUE_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', { pending: false, type: 'getTicketQueue' })
        })
    })
  },

  getEditTicketQueue({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      const { id } = rootState.route.params

      commit('SET_PENDING', { pending: true, type: 'getEditTicketQueue' })

      this.api.item
        .getEditItem('ticketQueues', id)
        .then(({ data }) => {
          commit('GET_TICKET_QUEUE_SUCCESS', data.ticketQueue)
          delete data.ticketQueue
          commit('GET_TICKET_QUEUE_PRESETS_SUCCESS', data)
          resolve()
        })
        .catch(error => {
          commit('GET_TICKET_QUEUE_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', { pending: false, type: 'getEditTicketQueue' })
        })
    })
  },

  editTicketQueue({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDING', { pending: true, type: 'editTicketQueue' })

      this.api.item
        .editItem('ticketQueues', state.ticketQueue)
        .then(({ data }) => {
          if (data && data.errors) {
            commit('EDIT_TICKET_QUEUE_FAIL', data.errors)
            return
          }
          commit('EDIT_TICKET_QUEUE_SUCCESS')
          resolve(data)
        })
        .catch(error => {
          commit('EDIT_TICKET_QUEUE_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', { pending: false, type: 'editTicketQueue' })
        })
    })
  },
}

const mutations = {
  updateField,
  SET_PENDING: (state, { pending, type = 'default' }) => {
    state.pending = { ...state.pending, [type]: pending }
  },
  CLEAR_TICKET_QUEUE(state) {
    state.ticketQueue = cloneDeep(ticketQueue)
    state.ticketQueuePresets = {}
    state.errors = {}
  },
  ADD_USER(state) {
    state.ticketQueue.users.push({
      user_id: null,
    })
  },
  REMOVE_USER(state, index) {
    state.ticketQueue.ticketQueue.splice(index, 1)
  },
  ADD_TICKET_QUEUE_SUCCESS(state) {
    state.errors = {}
  },
  ADD_TICKET_QUEUE_FAIL(state, errors) {
    state.errors = errors
  },
  GET_TICKET_QUEUE_PRESETS_SUCCESS(state, data) {
    state.ticketQueuePresets = data
    state.errors = {}
  },
  GET_TICKET_QUEUE_PRESETS_FAIL(state, errors) {
    state.errors = errors
  },
  GET_TICKET_QUEUE_SUCCESS(state, data) {
    state.ticketQueue = data
    state.errors = {}
  },
  GET_TICKET_QUEUE_FAIL(state, errors) {
    state.errors = errors
  },
  EDIT_TICKET_QUEUE_SUCCESS(state) {
    state.errors = {}
  },
  EDIT_TICKET_QUEUE_FAIL(state, errors) {
    state.errors = errors
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
