// Domain Store

import { getField, updateField } from 'vuex-map-fields'
import { clone } from 'lodash'

const domain = {
  id: null,
  client_id: null,
  name: null,
  tld: null,
  auth_code: null,
  owner_handle_id: null,
  admin_handle_id: null,
  tech_handle_id: null,
  zone_handle_id: null,
  owner_handle: {},
  admin_handle: {},
  tech_handle: {},
  zone_handle: {},
  client: {},
  ns1: null,
  ns2: null,
  ns3: null,
  ns4: null,
  ns5: null,
}

const state = {
  domain: clone(domain),
  pending: false,
  pendingDomainCheck: false,
  pendingSave: false,
  errors: {},
  status: null,
}

const getters = {
  getField,
}

const actions = {
  getDomain({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta
      const { id } = rootState.route.params

      commit('SET_PENDING', true)

      this.api.item
        .getItem(type, id)
        .then(({ data }) => {
          commit('GET_DOMAIN_SUCCESS', data.domain)
          resolve()
        })
        .catch(error => {
          commit('GET_DOMAIN_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  getEditDomain({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta
      const { id } = rootState.route.params

      commit('SET_PENDING', true)

      this.api.item
        .getEditItem(type, id)
        .then(({ data }) => {
          commit('GET_DOMAIN_SUCCESS', data.domain)
          resolve()
        })
        .catch(error => {
          commit('GET_DOMAIN_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  createDomain({ commit, state, rootState }) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta

      commit('SET_PENDING', true)

      delete state.domain.client
      delete state.domain.admin_handle
      delete state.domain.owner_handle
      delete state.domain.tech_handle
      delete state.domain.zone_handle

      this.api.item
        .addItem(type, state.domain)
        .then(({ data }) => {
          if (data && data.errors) {
            commit('ADD_DOMAIN_FAIL', data.errors)
            resolve()
          }
          commit('ADD_DOMAIN_SUCCESS')
          resolve(data)
        })
        .catch(error => {
          commit('ADD_DOMAIN_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  editDomain({ commit, state, rootState }) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta

      commit('SET_PENDINGSAVE', true)

      delete state.domain.client
      delete state.domain.admin_handle
      delete state.domain.owner_handle
      delete state.domain.tech_handle
      delete state.domain.zone_handle

      this.api.item
        .editItem(type, state.domain)
        .then(({ data }) => {
          if (data && data.errors) {
            commit('EDIT_DOMAIN_FAIL', data.errors)
            resolve()
          }
          commit('EDIT_DOMAIN_SUCCESS')
          resolve(data)
        })
        .catch(error => {
          commit('EDIT_DOMAIN_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDINGSAVE', false)
        })
    })
  },
  checkDomain({ commit }, domain) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDINGDOMAINCHECK', true)

      this.api.domain
        .checkDomain(domain)
        .then(({ data }) => {
          commit('CHECK_DOMAIN_SUCCESS', data)
          resolve(data)
        })
        .catch(error => {
          commit('CHECK_DOMAIN_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDINGDOMAINCHECK', false)
        })
    })
  },
  clearDomain({ commit }) {
    commit('CLEAR_DOMAIN')
  },
  clearStatus({ commit }) {
    commit('CLEAR_STATUS')
  },
}

const mutations = {
  CLEAR_DOMAIN(state) {
    state.domain = clone(domain)
    state.status = null
    state.errors = {}
  },
  CLEAR_STATUS(state) {
    state.status = null
  },
  GET_DOMAIN_SUCCESS(state, domain) {
    state.errors = {}
    state.domain = domain
  },
  GET_DOMAIN_FAIL(state, errors) {
    state.errors = errors
  },
  ADD_DOMAIN_SUCCESS(state) {
    state.errors = {}
  },
  ADD_DOMAIN_FAIL(state, errors) {
    state.errors = errors
  },
  EDIT_DOMAIN_SUCCESS(state) {
    state.errors = {}
  },
  EDIT_DOMAIN_FAIL(state, errors) {
    state.errors = errors
  },
  CHECK_DOMAIN_SUCCESS(state, data) {
    state.errors = {}
    state.status = data.status
  },
  CHECK_DOMAIN_FAIL(state, errors) {
    state.errors = errors
  },
  updateField,
}

const object_types = [
  'pending',
  'pendingDomainCheck',
  'pendingSave',
]

object_types.forEach(object_type => {
  mutations['SET_' + object_type.toUpperCase()] = (state, payload) => {
    state[object_type] = payload
  }
})

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
