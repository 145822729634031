import http from './http'

export default {
  request(url, method, payload) {
    return http({
      method,
      url,
      data: payload,
    })
  },
}
