import http from './http'

export default {
  getItem(type, id) {
    return http.get(`${type}/view/${id}`)
  },
  getEditItem(type, id, controller = null) {
    return http.get(`${type}/edit/${id}`, {
      signal: controller?.signal,
    })
  },
  getOptions(type) {
    return http.get(`${type}/add`)
  },
  removeItem(type, id) {
    return http.delete(`${type}/delete/${id}`)
  },
  editItem(type, data) {
    return http.put(`${type}/edit/${data.id}`, data)
  },
  addItem(type, data) {
    if (type === 'zones') {
      return http.post(`${type}/createZone`, data)
    }
    if (type === 'domains') {
      return http.post(`${type}/register`, data)
    }
    return http.post(`${type}/add`, data)
  },
}
