// Zone Store

import { getField, updateField } from 'vuex-map-fields'
import { clone } from 'lodash'

const zone = {
  id: null,
  name: null,
  ip: null,
  master: null,
  last_check: null,
  type: null,
  notified_serial: null,
  active: null,
  client_id: null,
  template: 'default',
  connection: 'default',
  zone_records: [],
}

const zone_record = {
  // id: null,
  // zone_id: null,
  name: null,
  type: 'A',
  content: null,
  ttl: null,
  prio: null,
  // change_date: null,
  // active: null,
}

const state = {
  zone: clone(zone),
  zone_record: clone(zone_record),
  pending: false,
  pendingDeleteRecord: false,
  pendingRefresh: false,
  pendingSave: false,
  errors: {},
  filterRecordsQuery: '',
  connections: [],
  templates: [],
}

const getters = {
  records: state => {
    if (state.filterRecordsQuery) {
      return state.zone.zone_records.filter(record => record.name.indexOf(state.filterRecordsQuery) > -1)
    }
    return state.zone && state.zone.zone_records
  },
  // errors: state => state.data && state.zone && state.zone.error && state.zone.error.zone_records,
  getField,
}

const actions = {
  getZone({ commit, rootState }, refresh = false) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta
      const { id } = rootState.route.params

      if (refresh) {
        commit('SET_PENDINGREFRESH', true)
      } else {
        commit('SET_PENDING', true)
      }

      this.api.item
        .getItem(type, id)
        .then(({ data }) => {
          commit('GET_ZONE_SUCCESS', data.zone)
          resolve()
        })
        .catch(error => {
          commit('GET_ZONE_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          if (refresh) {
            commit('SET_PENDINGREFRESH', false)
          } else {
            commit('SET_PENDING', false)
          }
        })
    })
  },
  createZone({ commit, state, rootState }) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta

      commit('SET_PENDING', true)

      // delete state.zone.active
      // delete state.zone.client_id
      // delete state.zone.id
      // delete state.zone.zone_records
      // delete state.zone.last_check
      // delete state.zone.master
      // delete state.zone.notified_serial
      // delete state.zone.type

      this.api.item
        .addItem(type, state.zone)
        .then(({ data }) => {
          if (data && data.errors) {
            commit('ADD_ZONE_FAIL', data.errors)
            resolve()
          }
          commit('ADD_ZONE_SUCCESS')
          resolve(data)
        })
        .catch(error => {
          commit('ADD_ZONE_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  editZone({ commit, state, rootState }) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta

      commit('SET_PENDINGSAVE', true)

      this.api.item
        .editItem(type, state.zone)
        .then(({ data }) => {
          if (data && data.errors) {
            commit('EDIT_ZONE_FAIL', data.errors)
            return
          }
          commit('EDIT_ZONE_SUCCESS', data.zone)
          resolve(data)
        })
        .catch(error => {
          commit('EDIT_ZONE_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDINGSAVE', false)
        })
    })
  },
  resetZone({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      const { id } = rootState.route.params

      commit('SET_PENDING', true)

      this.api.zones
        .resetZone(id)
        .then(({ data }) => {
          if (data && data.errors) {
            commit('RESET_ZONE_FAIL', data.errors)
            resolve()
          }
          commit('RESET_ZONE_SUCCESS', data.zone)
          resolve(data)
        })
        .catch(error => {
          commit('RESET_ZONE_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  deleteZone({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      const { id } = rootState.route.params

      commit('SET_PENDING', true)

      this.api.zones
        .deleteZone(id)
        .then(({ data }) => {
          if (data && data.errors) {
            commit('DELETE_ZONE_FAIL', data.errors)
            resolve()
          }
          commit('DELETE_ZONE_SUCCESS', data)
          resolve(data)
        })
        .catch(error => {
          commit('DELETE_ZONE_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  deleteRecord({ commit, dispatch }, id) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDINGDELETERECORD', true)

      this.api.zones
        .deleteRecord(id)
        .then(({ data }) => {
          if (data && data.errors) {
            commit('DELETE_RECORD_FAIL', data.errors)
            resolve()
          }
          commit('DELETE_RECORD_SUCCESS')
          dispatch('getZone', true)
          resolve(data)
        })
        .catch(error => {
          commit('DELETE_RECORD_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDINGDELETERECORD', false)
        })
    })
  },
  getConnections({ commit }) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDING', true)

      this.api.zones
        .getConnections()
        .then(({ data }) => {
          commit('GET_ZONE_CONNECTIONS_SUCCESS', data)
          resolve()
        })
        .catch(error => {
          commit('GET_ZONE_CONNECTIONS_ERROR', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  getTemplates({ commit }) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDING', true)

      this.api.zones
        .getTemplates()
        .then(({ data }) => {
          commit('GET_ZONE_TEMPLATES_SUCCESS', data)
          resolve()
        })
        .catch(error => {
          commit('GET_ZONE_TEMPLATES_ERROR', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  addRecord({ commit, state }) {
    commit('ADD_RECORD', state.zone_record)
  },
  removeRecord({ commit }, record) {
    commit('REMOVE_RECORD', record)
  },
  clearZone({ commit }) {
    commit('CLEAR_ZONE')
  },
}

const mutations = {
  CLEAR_ZONE(state) {
    state.zone = clone(zone)
    state.errors = {}
  },
  GET_ZONE_SUCCESS(state, zone) {
    state.errors = {}
    state.zone = zone
  },
  GET_ZONE_FAIL(state, errors) {
    state.errors = errors
  },
  ADD_ZONE_SUCCESS(state) {
    state.errors = {}
  },
  ADD_ZONE_FAIL(state, errors) {
    state.errors = errors
  },
  EDIT_ZONE_SUCCESS(state, zone) {
    state.errors = {}
    state.zone = zone
  },
  EDIT_ZONE_FAIL(state, errors) {
    state.errors = errors
  },
  RESET_ZONE_SUCCESS(state, zone) {
    state.zone = zone
    state.errors = {}
  },
  RESET_ZONE_FAIL(state, errors) {
    state.errors = errors
  },
  DELETE_ZONE_SUCCESS(state, data) {
    state.data = data
    state.errors = {}
  },
  DELETE_ZONE_FAIL(state, errors) {
    state.errors = errors
  },
  DELETE_RECORD_SUCCESS(state) {
    state.errors = {}
  },
  DELETE_RECORD_FAIL(state, errors) {
    state.errors = errors
  },
  SET_FILTER_RECORDS_QUERY(state, value) {
    state.filterRecordsQuery = value
  },
  ADD_RECORD(state, record) {
    state.zone.zone_records.push(record)
    state.zone_record = clone(zone_record)
  },
  REMOVE_RECORD(state, record) {
    state.zone.zone_records.splice(state.zone.zone_records.indexOf(record), 1)
  },
  GET_ZONE_CONNECTIONS_SUCCESS(state, { connections }) {
    state.connections = connections
    state.errors = {}
  },
  GET_ZONE_CONNECTIONS_ERROR(state, errors) {
    state.errors = errors
  },
  GET_ZONE_TEMPLATES_SUCCESS(state, { templates }) {
    state.templates = templates
    state.errors = {}
  },
  GET_ZONE_TEMPLATES_ERROR(state, errors) {
    state.errors = errors
  },
  updateField,
}

const object_types = [
  'pending',
  'pendingDeleteRecord',
  'pendingRefresh',
  'pendingSave',
]

object_types.forEach(object_type => {
  mutations['SET_' + object_type.toUpperCase()] = (state, payload) => {
    state[object_type] = payload
  }
})

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
