const state = {
  tickets: [],
  ticketStatistics: {},
  pagination: {},
  pending: {},
  errors: {},
}

const getters = {}

const actions = {
  getTicketboard({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDING', { pending: true, type: 'getTicketboard' })

      this.api.items
        .getItems('tickets/ticketboard', payload)
        .then(({ data }) => {
          commit('GET_TICKETS_SUCCESS', data)
          commit('CLEAR_ERROR', 'getTicketboard')
          resolve()
        })
        .catch(error => {
          commit('SET_ERROR', { error, type: 'getTicketboard' })
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', { pending: false, type: 'getTicketboard' })
        })
    })
  },
  getTickets({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDING', { pending: true, type: 'getTickets' })

      this.api.items
        .getItems('tickets', payload)
        .then(({ data }) => {
          commit('GET_TICKETS_SUCCESS', data)
          commit('CLEAR_ERROR', 'getTickets')
          resolve()
        })
        .catch(error => {
          commit('SET_ERROR', { error, type: 'getTickets' })
          // commit('GET_TICKETS_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', { pending: false, type: 'getTickets' })
        })
    })
  },
  getMyTickets({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDING', { pending: true, type: 'getMyTickets' })

      this.api.items
        .getItems('tickets/myTickets', payload)
        .then(({ data }) => {
          commit('GET_TICKETS_SUCCESS', data)
          commit('CLEAR_ERROR', 'getMyTickets')
          resolve()
        })
        .catch(error => {
          commit('SET_ERROR', { error, type: 'getMyTickets' })
          // commit('GET_TICKETS_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', { pending: false, type: 'getMyTickets' })
        })
    })
  },
  getTicketStatistics({ commit }) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDING', { pending: true, type: 'getTicketStatistics' })

      this.api.items
        .getItems('tickets/getTicketStatistics')
        .then(({ data }) => {
          commit('GET_TICKET_STATISTICS_SUCCESS', data)
          commit('CLEAR_ERROR', 'getTicketStatistics')
          resolve()
        })
        .catch(error => {
          commit('SET_ERROR', { error, type: 'getTicketStatistics' })
          // commit('GET_TICKET_STATISTICS_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', { pending: false, type: 'getTicketStatistics' })
        })
    })
  },
  deleteTicket({ commit }, ticketId) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDING', { pending: true, type: 'deleteTicket' })

      this.api.item
        .removeItem('tickets', ticketId)
        .then(({ data }) => {
          if (data && data.errors) {
            // commit('DELETE_TICKET_FAIL', data.errors)
            commit('SET_ERROR', { error: data.errors, type: 'deleteTicket' })
            resolve()
            return
          }
          commit('DELETE_TICKET_SUCCESS', data)
          commit('CLEAR_ERROR', 'deleteTicket')
          resolve(data)
        })
        .catch(error => {
          commit('SET_ERROR', { error, type: 'deleteTicket' })
          // commit('DELETE_TICKET_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', { pending: false, type: 'deleteTicket' })
        })
    })
  },
  takeTicket({ commit }, ticketId) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDING', { pending: true, type: `takeTicket-${ticketId}` })

      this.api.ticket
        .takeTicket(ticketId)
        .then(({ data }) => {
          if (data && data.errors) {
            // commit('TAKE_TICKET_FAIL', data.errors)
            commit('SET_ERROR', { error: data.errors, type: 'takeTicket' })
            resolve()
            return
          }
          commit('TAKE_TICKET_SUCCESS', data)
          commit('CLEAR_ERROR', 'takeTicket')
          resolve(data)
        })
        .catch(error => {
          commit('SET_ERROR', { error, type: 'takeTicket' })
          // commit('TAKE_TICKET_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', { pending: false, type: `takeTicket-${ticketId}` })
        })
    })
  },
}

const mutations = {
  SET_PENDING: (state, { pending, type = 'default' }) => {
    state.pending = { ...state.pending, [type]: pending }
  },
  SET_ERROR: (state, { error, type }) => {
    state.errors = { ...state.errors, [type]: error }
  },
  CLEAR_ERROR: (state, type) => {
    state.errors = { ...state.errors, [type]: null }
  },
  CLEAR_TICKETS: state => {
    state.tickets = []
  },
  GET_TICKETS_SUCCESS(state, { pagination, tickets }) {
    state.tickets = tickets
    state.pagination = pagination
  },
  GET_TICKET_STATISTICS_SUCCESS(state, ticketStatistics) {
    state.ticketStatistics = ticketStatistics
  },
  DELETE_TICKET_SUCCESS(state) {
    return
  },
  TAKE_TICKET_SUCCESS(state) {
    state.errors = {}
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
