const state = {
  timeReports: [],
  unbilledCustomers: [],
  pagination: {},
  pending: {},
  errors: {},
}

const getters = {}

const actions = {
  getTimeReports({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDING', { pending: true, type: 'getTimeReports' })

      this.api.items
        .getItems('timeReports', payload)
        .then(({ data }) => {
          commit('GET_TIME_REPORTS_SUCCESS', data)
          commit('CLEAR_ERROR', 'getTimeReports')
          resolve()
        })
        .catch(error => {
          commit('SET_ERROR', { error, type: 'getTimeReports' })
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', { pending: false, type: 'getTimeReports' })
        })
    })
  },

  deleteTimeReport({ commit }, reportId) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDING', { pending: true, type: 'deleteTimeReport' })

      this.api.item
        .removeItem('timeReports', reportId)
        .then(({ data }) => {
          if (data && data.errors) {
            // commit('DELETE_TICKET_FAIL', data.errors)
            commit('SET_ERROR', { error: data.errors, type: 'deleteTimeReport' })
            resolve()
            return
          }
          commit('DELETE_TIME_REPORT_SUCCESS', data)
          commit('CLEAR_ERROR', 'deleteTimeReport')
          resolve(data)
        })
        .catch(error => {
          commit('SET_ERROR', { error, type: 'deleteTimeReport' })
          // commit('DELETE_TICKET_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', { pending: false, type: 'deleteTimeReport' })
        })
    })
  },

  createInvoiceFromTimeReport({ commit }, reportId) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDING', { pending: true, type: 'reportToInvoice' })

      this.api.timeReport
        .toInvoice(reportId)
        .then(({ data }) => {
          if (data && data.errors) {
            commit('SET_ERROR', { error: data.errors, type: 'reportToInvoice' })
            resolve()
            return
          }
          commit('CREATE_INVOICE_FROM_TIME_REPORT_SUCCESS', data)
          commit('CLEAR_ERROR', 'reportToInvoice')
          resolve(data)
        })
        .catch(error => {
          commit('SET_ERROR', { error, type: 'reportToInvoice' })
          // commit('DELETE_TICKET_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', { pending: false, type: 'reportToInvoice' })
        })
    })
  },
}

const mutations = {
  SET_PENDING: (state, { pending, type = 'default' }) => {
    state.pending = { ...state.pending, [type]: pending }
  },
  SET_ERROR: (state, { error, type }) => {
    state.errors = { ...state.errors, [type]: error }
  },
  CLEAR_ERROR: (state, type) => {
    state.errors = { ...state.errors, [type]: null }
  },
  CLEAR_TIME_REPORTS: state => {
    state.timeReports = []
    state.unbilledCustomers = []
  },
  GET_TIME_REPORTS_SUCCESS(state, { pagination, timeReports, clients }) {
    state.timeReports = timeReports
    state.unbilledCustomers = clients
    state.pagination = pagination
  },
  DELETE_TIME_REPORT_SUCCESS() {
    return
  },
  CREATE_INVOICE_FROM_TIME_REPORT_SUCCESS() {
    return
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
