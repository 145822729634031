import http from './http'

export default {
  getPdf(reportId) {
    return http.get(`timeReports/viewPdf/${reportId}`)
  },
  getUnlinkedTasks(clientId) {
    return http.get(`timeReports/getUnlinkedTasks/${clientId}`)
  },
  toInvoice(reportId) {
    return http.get(`timeReports/toInvoice/${reportId}`)
  },
}
