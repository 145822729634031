// Category Store

import { clone } from 'lodash'
import { getField, updateField } from 'vuex-map-fields'

const category = {
  id: null,
  name: null,
  subscriptionstext: null,
}

const state = {
  category: clone(category),
  pending: false,
  pendingSave: false,
  foundCategories: [],
  errors: {},
}

const getters = {
  getField,
}

const actions = {
  searchCategory({ commit }, searchString) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDING', true)
      this.api.category
        .searchCategory(searchString)
        .then(({ data }) => {
          commit('SEARCH_CATEGORY_SUCCESS', data)
          resolve(data)
        })
        .catch(error => {
          commit('SEARCH_CATEGORY_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  getCategory({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta
      const { id } = rootState.route.params

      commit('SET_PENDING', true)

      this.api.item
        .getItem(type, id)
        .then(({ data }) => {
          commit('GET_CATEGORY_SUCCESS', data.category)
          resolve()
        })
        .catch(error => {
          commit('GET_CATEGORY_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  getEditCategory({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta
      const { id } = rootState.route.params

      commit('SET_PENDING', true)

      this.api.item
        .getEditItem(type, id)
        .then(({ data }) => {
          commit('GET_CATEGORY_SUCCESS', data.category)
          resolve()
        })
        .catch(error => {
          commit('GET_CATEGORY_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  createCategory({ commit, state, rootState }) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta

      commit('SET_PENDING', true)

      this.api.item
        .addItem(type, state.category)
        .then(({ data }) => {
          if (data && data.errors) {
            commit('ADD_CATEGORY_FAIL', data.errors)
            resolve()
          }
          commit('ADD_CATEGORY_SUCCESS')
          resolve(data)
        })
        .catch(error => {
          commit('ADD_CATEGORY_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  editCategory({ commit, state, rootState }) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta

      commit('SET_PENDINGSAVE', true)

      this.api.item
        .editItem(type, state.category)
        .then(({ data }) => {
          if (data && data.errors) {
            commit('EDIT_CATEGORY_FAIL', data.errors)
            resolve()
          }
          commit('EDIT_CATEGORY_SUCCESS', data.category)
          resolve(data)
        })
        .catch(error => {
          commit('EDIT_CATEGORY_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDINGSAVE', false)
        })
    })
  },
  clearCategory({ commit }) {
    commit('CLEAR_CATEGORY')
  },
  clearFoundCategories({ commit }) {
    commit('CLEAR_FOUND_CATEGORIES')
  },
}

const mutations = {
  CLEAR_CATEGORY(state) {
    state.category = clone(category)
    state.errors = {}
  },
  CLEAR_FOUND_CATEGORIES(state) {
    state.foundCategories = []
    state.errors = {}
  },
  SEARCH_CATEGORY_SUCCESS(state, data) {
    if (data !== null) {
      state.foundCategories = data.categories
    } else {
      state.foundCategories = []
    }
  },
  SEARCH_CATEGORY_FAIL(state, errors) {
    state.errors = errors
  },
  GET_CATEGORY_SUCCESS(state, category) {
    state.errors = {}
    state.category = category
  },
  GET_CATEGORY_FAIL(state, errors) {
    state.errors = errors
  },
  ADD_CATEGORY_SUCCESS(state) {
    state.errors = {}
  },
  ADD_CATEGORY_FAIL(state, errors) {
    state.errors = errors
  },
  EDIT_CATEGORY_SUCCESS(state) {
    state.errors = {}
  },
  EDIT_CATEGORY_FAIL(state, errors) {
    state.errors = errors
  },
  updateField,
}

const object_types = [
  'pending',
  'pendingSave',
]

object_types.forEach(object_type => {
  mutations['SET_' + object_type.toUpperCase()] = (state, payload) => {
    state[object_type] = payload
  }
})

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
