// Products

const state = {
  products: [],
  pagination: {},
  pending: false,
  errors: {},
  foundProductByArtNr: [],
  foundProductByTitle: [],
  pendingSearchByArtNR: false,
  pendingSearchByTitle: false,
}

const getters = {}

const actions = {
  getProducts({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta

      commit('SET_PENDING', true)

      this.api.items
        .getItems(type, payload)
        .then(({ data }) => {
          commit('GET_PRODUCTS_SUCCESS', data)
          resolve()
        })
        .catch(error => {
          commit('GET_PRODUCTS_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  removeProduct({ commit, rootState }, productId) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta

      commit('SET_PENDING', true)

      this.api.item
        .removeItem(type, productId)
        .then(({ data }) => {
          if (data && data.errors) {
            commit('REMOVE_PRODUCT_FAIL', data.errors)
            resolve()
          }
          commit('REMOVE_PRODUCT_SUCCESS', data)
          resolve(data)
        })
        .catch(error => {
          commit('REMOVE_PRODUCT_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  searchProductByArtNr({ commit }, searchArtNr) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDINGSEARCHBYARTNR', true)

      this.api.product
        .searchByArtNr(searchArtNr)
        .then(({ data }) => {
          commit('SEARCH_PRODUCT_ART_NR_SUCCESS', data)
          resolve(data)
        })
        .catch(error => {
          commit('SEARCH_PRODUCT_ART_NR_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDINGSEARCHBYARTNR', false)
        })
    })
  },
  searchProductByTitle({ commit }, searchTitle) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDINGSEARCHBYTITLE', true)

      this.api.product
        .searchByTitle(searchTitle)
        .then(({ data }) => {
          commit('SEARCH_PRODUCT_TITLE_SUCCESS', data)
          resolve(data)
        })
        .catch(error => {
          commit('SEARCH_PRODUCT_TITLE_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDINGSEARCHBYTITLE', false)
        })
    })
  },
  clearProductSearchByArtNr({ commit }) {
    commit('CLEAR_PRODUCT_SEARCH_BY_ART_NR')
  },
  clearProductSearchByTitle({ commit }) {
    commit('CLEAR_PRODUCT_SEARCH_BY_TITLE')
  },
}

const mutations = {
  GET_PRODUCTS_SUCCESS(state, { products, pagination }) {
    state.errors = {}
    state.products = products
    state.pagination = pagination
  },
  GET_PRODUCTS_FAIL(state, errors) {
    state.errors = errors
  },
  REMOVE_PRODUCT_SUCCESS(state) {
    state.errors = {}
  },
  REMOVE_PRODUCT_FAIL(state, errors) {
    state.errors = errors
  },
  SEARCH_PRODUCT_ART_NR_SUCCESS(state, data) {
    if (data !== null) {
      state.foundProductByArtNr = data
    } else {
      state.foundProductByArtNr = []
    }
  },
  SEARCH_PRODUCT_ART_NR_FAIL(state, errors) {
    state.errors = errors
  },
  SEARCH_PRODUCT_TITLE_SUCCESS(state, data) {
    if (data !== null) {
      state.foundProductByTitle = data
    } else {
      state.foundProductByTitle = []
    }
  },
  SEARCH_PRODUCT_TITLE_FAIL(state, errors) {
    state.errors = errors
  },
  CLEAR_PRODUCT_SEARCH_BY_ART_NR(state) {
    state.foundProductByArtNr = []
  },
  CLEAR_PRODUCT_SEARCH_BY_TITLE(state) {
    state.foundProductByTitle = []
  },
}

const object_types = [
  'pending',
  'pendingSearchByArtNR',
  'pendingSearchByTitle',
]

object_types.forEach(object_type => {
  mutations['SET_' + object_type.toUpperCase()] = (state, payload) => {
    state[object_type] = payload
  }
})

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
