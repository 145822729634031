import http from './http'

export default {
  setStatus(invoice) {
    return http.get(`invoices/status/${invoice.id}/${invoice.status}`)
  },
  getPdf(invoiceId) {
    return http.get(`invoices/viewPdf/${invoiceId}`)
  },
  getPdfPreview(invoice) {
    return http.post('invoices/viewPdf', {
      invoice,
    })
  },
}
