<template>
  <AppCard title="Multiple Requester" text="To post multiple datas">
    <v-container grid-list-xl pt-3>
      <v-form @submit.prevent="onMultipleRequestSubmit">
        <v-row class="pad" wrap>
          <v-col cols="12" sm="8">
            <v-text-field
              v-model="multiurl"
              hide-details="auto"
              outlined
              label="Url"
              placeholder="Path"
              :disabled="pending"
              required
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-select
              v-model="multimethod"
              hide-details="auto"
              :items="methods"
              outlined
              label="Method"
              :disabled="pending"
            />
          </v-col>

          <v-col cols="12">
            <v-textarea
              v-model="multipayload"
              hide-details="auto"
              auto-grow
              outlined
              rows="5"
              row-height="15"
              label="Enter JSON"
              :disabled="pending"
            />
          </v-col>
          <v-col py-0 cols="12" text-xs-right>
            <v-btn type="submit" class="mx-0 font-weight-light" color="success" :disabled="pending">
              Submit Request
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

      <v-row v-if="response" class="response mt-4">
        <v-col cols="12">
          <div v-if="pending" class="box-body">Loading...</div>
          <div v-if="response" class="box-body">
            <p><b>Success</b></p>
            <pre>{{ response }}</pre>
          </div>
          <div v-if="error" class="box-body">
            <b>Error</b>
            <pre>{{ error }}</pre>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </AppCard>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'

import api from '@/api'
import AppCard from '@/components/UI/AppCard'

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array)
  }
}

export default defineComponent({
  name: 'MultiRequester',
  components: {
    AppCard,
  },
  setup() {
    const error = ref(false)
    const multimethod = ref('POST')
    const multipayload = ref('')
    const multiurl = ref('')
    const pending = ref(false)
    const response = ref('')
    const methods = [
      'POST',
      'GET',
      'PUT',
      'PATCH',
      'DELETE',
    ]

    const onMultipleRequestSubmit = _ => {
      const payload = JSON.parse('[' + multipayload.value + ']')

      const start = async () => {
        await asyncForEach(payload, async ele => {
          pending.value = true
          error.value = false
          response.value = false

          await api.test
            .request(multiurl.value, multimethod.value, ele)
            .then(({ data }) => {
              // TODO: stack responses
              response.value = data
              pending.value = false
            })
            .catch(error => {
              error.value = error
              pending.value = false
            })
        })
      }

      start()
    }

    return {
      pending,
      multimethod,
      multipayload,
      multiurl,
      error,
      response,
      methods,
      onMultipleRequestSubmit,
    }
  },
})
</script>
