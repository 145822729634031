import http from './http'

export default {
  searchByArtNr(searchArtNr) {
    return http.get(`products/getByArtNr/${searchArtNr}`)
  },
  searchByTitle(searchTitle) {
    return http.get(`products/getByTitle/${searchTitle}`)
  },
}
