import auth from './auth'
import category from './category'
import client from './client'
import domain from './domain'
import handle from './handle'
import invoice from './invoice'
import item from './item'
import items from './items'
import product from './product'
import quote from './quote'
import test from './test'
import ticket from './ticket'
import timeReport from './timeReport'
import user from './user'
import zones from './zones'

export default {
  auth,
  category,
  client,
  domain,
  handle,
  invoice,
  item,
  items,
  product,
  quote,
  test,
  ticket,
  timeReport,
  user,
  zones,
}
