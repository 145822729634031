<template>
  <v-container class="pt-10">
    <section class="content">
      <!-- Requester box -->
      <v-row>
        <v-col cols="12">
          <Requester />
        </v-col>
      </v-row>

      <!-- Requester box -->
      <v-row>
        <v-col cols="12">
          <MultiRequester />
        </v-col>
      </v-row>

      <!-- Requester box -->
      <v-row>
        <v-col cols="12">
          <Uploader />
        </v-col>
      </v-row>

      <!-- Toasts -->
      <v-row>
        <v-col cols="12">
          <AppCard :title="$t('Toasts')">
            <v-btn class="mr-3 mb-3" color="success" @click="addNewToast('success')">addToast success</v-btn>
            <v-btn class="mr-3 mb-3" color="warning" @click="addNewToast('warning')">addToast warning</v-btn>
            <v-btn class="mr-3 mb-3" color="info" @click="addNewToast('info')">addToast info</v-btn>
            <v-btn class="mr-3 mb-3" color="error" @click="addNewToast('error')">addToast error</v-btn>
            <v-btn class="mr-3 mb-3" color="primary" @click="addNewToast('primary')">addToast primary</v-btn>
            <v-btn class="mr-3 mb-3" color="secondary" @click="addNewToast('secondary')">addToast secondary</v-btn>
            <v-btn class="mr-3 mb-3" color="accent" @click="addNewToast('accent')">addToast accent</v-btn>
            <v-btn class="mr-3 mb-3" color="tertiary" @click="addNewToast('tertiary')">addToast tertiary</v-btn>
          </AppCard>
        </v-col>
      </v-row>
    </section>
  </v-container>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

import AppCard from '@/components/UI/AppCard'
import Requester from '@/components/Test/Requester'
import MultiRequester from '@/components/Test/MultiRequester'
import Uploader from '@/components/Test/Uploader'

export default defineComponent({
  name: 'ViewDummy',
  components: {
    Requester,
    MultiRequester,
    Uploader,
    AppCard,
  },
  setup(props, { root: { $store } }) {
    const addToast = toast => $store.commit('notifications/ADD_TOAST', toast)

    const addNewToast = type => {
      addToast({
        msg: 'Eine kurze Info',
        type: type,
      })
    }

    return {
      addNewToast,
    }
  },
})
</script>
