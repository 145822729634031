import { getField, updateField } from 'vuex-map-fields'
import { cloneDeep } from 'lodash'

const timeReport = {
  client_id: null,
  name: null,
  month: null,
  year: null,
  tenant_id: null,
  time_tasks: [],
}

const state = {
  timeReport: cloneDeep(timeReport),
  timeReportPresets: {},
  clonedLinkedTasks: [],
  unlinkedTasks: [],
  timeReportPdf: null,
  pending: {},
  errors: {},
}

const getters = {
  getField,
  ticketsWithTasks: state => {
    const tickets = {}

    state.unlinkedTasks.forEach(task => {
      if (task.ticket) {
        const taskTicketId = task.ticket.id
        if (!tickets[taskTicketId]) {
          tickets[taskTicketId] = { ...task.ticket }
          tickets[taskTicketId].tasks = []
        }

        tickets[taskTicketId].tasks.push({ ...task })
      } else {
        if (!tickets.withOutTicket) {
          tickets.withOutTicket = { tasks: [] }
        }

        tickets.withOutTicket.tasks.push({ ...task })
      }
    })

    return tickets
  },
  clonedTimeReportTicketsWithTasks: state => {
    const tickets = {}

    state.clonedLinkedTasks.forEach(task => {
      if (task.ticket) {
        const taskTicketId = task.ticket.id
        if (!tickets[taskTicketId]) {
          tickets[taskTicketId] = { ...task.ticket }
          tickets[taskTicketId].tasks = []
        }

        tickets[taskTicketId].tasks.push({ ...task })
      } else {
        if (!tickets.withOutTicket) {
          tickets.withOutTicket = { tasks: [] }
        }

        tickets.withOutTicket.tasks.push({ ...task })
      }
    })

    return tickets
  },
  timeReportTicketsWithTasks: state => {
    const tickets = {}

    state.timeReport.time_tasks.forEach(task => {
      if (task.ticket) {
        const taskTicketId = task.ticket.id
        if (!tickets[taskTicketId]) {
          tickets[taskTicketId] = { ...task.ticket }
          tickets[taskTicketId].tasks = []
        }

        tickets[taskTicketId].tasks.push({ ...task })
      } else {
        if (!tickets.withOutTicket) {
          tickets.withOutTicket = { tasks: [] }
        }

        tickets.withOutTicket.tasks.push({ ...task })
      }
    })

    return tickets
  },
}

const actions = {
  getTimeReportPresets({ commit }) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDING', { pending: true, type: 'getTimeReportPresets' })

      this.api.item
        .getOptions('timeReports')
        .then(({ data }) => {
          commit('GET_TIME_REPORT_PRESETS_SUCCESS', data)
          resolve()
        })
        .catch(error => {
          commit('GET_TIME_REPORT_PRESETS_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', { pending: false, type: 'getTimeReportPresets' })
        })
    })
  },

  getUnlinkedTasks({ commit }, clientId) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDING', { pending: true, type: 'getUnlinkedTasks' })

      this.api.timeReport
        .getUnlinkedTasks(clientId)
        .then(({ data }) => {
          commit('GET_TIME_REPORT_UNLINKED_TASKS_SUCCESS', data.tasks)
          resolve()
        })
        .catch(error => {
          commit('GET_TIME_REPORT_UNLINKED_TASKS_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', { pending: false, type: 'getUnlinkedTasks' })
        })
    })
  },

  createTimeReport({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDING', { pending: true, type: 'createTimeReport' })

      this.api.item
        .addItem('timeReports', state.timeReport)
        .then(({ data }) => {
          if (data && data.errors) {
            commit('ADD_TIME_REPORT_FAIL', data.errors)
            return
          }
          commit('ADD_TIME_REPORT_SUCCESS')
          resolve(data)
        })
        .catch(error => {
          commit('ADD_TIME_REPORT_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', { pending: false, type: 'createTimeReport' })
        })
    })
  },

  getTimeReport({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      const { id } = rootState.route.params

      commit('SET_PENDING', { pending: true, type: 'getTimeReport' })

      this.api.item
        .getItem('timeReports', id)
        .then(({ data }) => {
          commit('GET_TIME_REPORT_SUCCESS', data.timeReport)

          // commit('GET_TIME_REPORT_PRESETS_SUCCESS', data)
          resolve()
        })
        .catch(error => {
          commit('GET_TIME_REPORT_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', { pending: false, type: 'getTimeReport' })
        })
    })
  },

  getEditTimeReport({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      const { id } = rootState.route.params

      commit('SET_PENDING', { pending: true, type: 'getEditTimeReport' })

      this.api.item
        .getEditItem('timeReports', id)
        .then(({ data }) => {
          commit('GET_EDIT_TIME_REPORT_SUCCESS', data.timeReport)
          delete data.timeReport
          commit('GET_TIME_REPORT_PRESETS_SUCCESS', data)
          resolve()
        })
        .catch(error => {
          commit('GET_TIME_REPORT_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', { pending: false, type: 'getEditTimeReport' })
        })
    })
  },

  editTimeReport({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDING', { pending: true, type: 'editTimeReport' })

      this.api.item
        .editItem('timeReports', state.timeReport)
        .then(({ data }) => {
          if (data && data.errors) {
            commit('EDIT_TIME_REPORT_FAIL', data.errors)
            return
          }
          commit('EDIT_TIME_REPORT_SUCCESS')
          resolve(data)
        })
        .catch(error => {
          commit('EDIT_TIME_REPORT_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', { pending: false, type: 'editTimeReport' })
        })
    })
  },

  getTimeReportPdf({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDING', { pending: true, type: 'getTimeReportPdf' })

      this.api.timeReport
        .getPdf(id)
        .then(({ data }) => {
          commit('GET_TIME_REPORT_PDF_SUCCESS', data.time_report)
          resolve()
        })
        .catch(error => {
          commit('GET_TIME_REPORT_PDF_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', { pending: false, type: 'getTimeReportPdf' })
        })
    })
  },
}

const mutations = {
  updateField,
  SET_PENDING: (state, { pending, type = 'default' }) => {
    state.pending = { ...state.pending, [type]: pending }
  },
  CLEAR_TIME_REPORT(state) {
    state.timeReport = cloneDeep(timeReport)
    state.timeReportPresets = {}
    state.timeReportPdf = null
    state.unlinkedTasks = []
    state.errors = {}
  },
  ADD_USER(state) {
    state.timeReport.users.push({
      user_id: null,
    })
  },
  REMOVE_USER(state, index) {
    state.timeReport.timeReport.splice(index, 1)
  },
  ADD_TIME_REPORT_SUCCESS(state) {
    state.errors = {}
  },
  ADD_TIME_REPORT_FAIL(state, errors) {
    state.errors = errors
  },
  GET_TIME_REPORT_PRESETS_SUCCESS(state, data) {
    state.timeReportPresets = data
    state.errors = {}
  },
  GET_TIME_REPORT_PRESETS_FAIL(state, errors) {
    state.errors = errors
  },
  GET_TIME_REPORT_UNLINKED_TASKS_SUCCESS(state, data) {
    state.unlinkedTasks = data
    state.errors = {}
  },
  GET_TIME_REPORT_UNLINKED_TASKS_FAIL(state, errors) {
    state.errors = errors
  },
  GET_TIME_REPORT_SUCCESS(state, data) {
    state.timeReport = data
    state.errors = {}
  },
  GET_TIME_REPORT_FAIL(state, errors) {
    state.errors = errors
  },
  GET_EDIT_TIME_REPORT_SUCCESS(state, data) {
    state.timeReport = data
    state.clonedLinkedTasks = data.time_tasks
    state.errors = {}
  },
  EDIT_TIME_REPORT_SUCCESS(state) {
    state.errors = {}
  },
  EDIT_TIME_REPORT_FAIL(state, errors) {
    state.errors = errors
  },
  GET_TIME_REPORT_PDF_SUCCESS(state, data) {
    state.errors = {}
    state.timeReportPdf = data
  },
  GET_TIME_REPORT_PDF_FAIL(state, errors) {
    state.errors = errors
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
