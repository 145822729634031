<template>
  <AppCard title="Requester">
    <v-container grid-list-xl pt-3>
      <v-form @submit.prevent="onRequestSubmit">
        <v-row class="pad" wrap>
          <v-col cols="12" sm="8">
            <v-text-field
              v-model="url"
              hide-details="auto"
              outlined
              label="Url"
              placeholder="Path"
              :disabled="pending"
              required
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-select
              v-model="method"
              hide-details="auto"
              :items="methods"
              outlined
              label="Method"
              :disabled="pending"
            />
          </v-col>

          <v-col cols="12">
            <div v-if="payload && jsonerror" class="text-danger">
              {{ jsonerror }}
            </div>
            <div v-if="!jsonerror" class="text-success">Valid JSON ✔</div>
          </v-col>

          <v-col cols="12">
            <v-textarea
              v-model="payload"
              hide-details="auto"
              label="Payload"
              auto-grow
              outlined
              rows="5"
              row-height="15"
            />
          </v-col>

          <v-col cols="12">
            <pre>{{ prettyFormat }}</pre>
          </v-col>

          <v-col py-0 cols="12" text-xs-right>
            <v-btn type="submit" class="mx-0 font-weight-light" color="success" :disabled="pending">
              Submit Request
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

      <v-row v-if="response" class="response mt-4">
        <v-col cols="12">
          <div v-if="pending" class="box-body">Loading...</div>
          <div v-if="response" class="box-body">
            <p><b>Success</b></p>
            <pre>{{ response }}</pre>
          </div>
          <div v-if="error" class="box-body">
            <b>Error</b>
            <pre>{{ error }}</pre>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </AppCard>
</template>

<script>
import { computed, defineComponent, ref } from '@vue/composition-api'

import api from '@/api'
import AppCard from '@/components/UI/AppCard'

export default defineComponent({
  name: 'Requester',
  components: {
    AppCard,
  },
  setup() {
    const error = ref(false)
    const method = ref('POST')
    const payload = ref('{}')
    const pending = ref(false)
    const response = ref('')
    const url = ref('')
    const jsonerror = ref('')
    const methods = [
      'POST',
      'GET',
      'PUT',
      'PATCH',
      'DELETE',
    ]

    const prettyFormat = computed(_ => {
        // reset error
      updateJsonError('')
      let jsonValue = ''
      try {
          // try to parse
        jsonValue = JSON.parse(payload.value)
      } catch (e) {
        updateJsonError(JSON.stringify(e.message))
        const textarea = document.getElementById('jsonText')
        if (jsonerror.value.indexOf('position') > -1) {
            // highlight error position
          const positionStr = jsonerror.value.lastIndexOf('position') + 8
          const posi = parseInt(
              jsonerror.value.substr(positionStr, jsonerror.value.lastIndexOf('"')),
            )
          if (posi >= 0) {
            textarea.setSelectionRange(posi, posi + 1)
          }
        }
        return ''
      }
      return JSON.stringify(jsonValue, null, 2)
    })

    const updateJsonError = error => {
      jsonerror.value = error
    }

    const onRequestSubmit = _ => {
      pending.value = true
      error.value = false
      response.value = false

      api.test
        .request(url.value, method.value, JSON.parse(payload.value))
        .then(({ data }) => {
          response.value = data
          pending.value = false
        })
        .catch(error => {
          error.value = error
          pending.value = false
        })
    }

    return {
      error,
      method,
      payload,
      pending,
      response,
      url,
      jsonerror,
      methods,
      prettyFormat,
      onRequestSubmit,
    }
  },
})
</script>
