<template>
  <apexchart :options="options" :series="series" />
</template>

<script>
import { defineComponent } from '@vue/composition-api'


export default defineComponent({
  name: 'BarChart',
  setup(props, { root: { $vuetify } }) {
    const options = {
      chart: {
        height: 350,
        type: 'bar',
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: .2,
        },
        toolbar: {
          show: false,
        },
      },
      colors: [
        $vuetify.theme.themes.light.success,
        $vuetify.theme.themes.light.error,
      ],
      dataLabels: {
        enabled: false,
      },
      grid: {
        strokeDashArray: 3,
      },
      xaxis: {
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
        ],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
    }

    const series = [
      {
        name: 'Comments',
        data: [
          120,
          90,
          130,
          140,
          49,
          62,
          100,
          91,
          148,
        ],
      },
      {
        name: 'Avg Comments per day',
        data: [
          120,
          120,
          110,
          110,
          35,
          90,
          130,
          80,
          100,
        ],
      },
    ]

    return {
      options,
      series,
    }
  },
})
</script>
