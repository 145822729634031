// Notifications Store
import { nanoid } from 'nanoid'

const state = {
  toasts: [],
}

const getters = {}

const actions = {}

const mutations = {
  ADD_TOAST: (state, toast) => {
    const newToast = {
      id: nanoid(),
      type: 'primary',
      ...toast,
    }
    state.toasts.push(newToast)
  },
  DELETE_TOAST: (state, toastId) => {
    const index = state.toasts.findIndex(t => t.id === toastId)
    state.toasts.splice(index, 1)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
