const state = {
  ticketQueues: [],
  pagination: {},
  pending: {},
  errors: {},
}

const getters = {}

const actions = {
  getTicketQueues({ commit }, page) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDING', { pending: true, type: 'getTicketQueues' })

      this.api.items
        .getItems('ticketQueues', { page })
        .then(({ data }) => {
          commit('GET_TICKET_QUEUES_SUCCESS', data)
          commit('CLEAR_ERROR', 'getTicketQueues')
          resolve()
        })
        .catch(error => {
          commit('SET_ERROR', { error, type: 'getTicketQueues' })
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', { pending: false, type: 'getTicketQueues' })
        })
    })
  },
  deleteTicketQueue({ commit }, queueId) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDING', { pending: true, type: 'deleteTicketQueue' })

      this.api.item
        .removeItem('ticketQueues', queueId)
        .then(({ data }) => {
          if (data && data.errors) {
            // commit('DELETE_TICKET_FAIL', data.errors)
            commit('SET_ERROR', { error: data.errors, type: 'deleteTicketQueue' })
            resolve()
            return
          }
          commit('DELETE_TICKET_QUEUES_SUCCESS', data)
          commit('CLEAR_ERROR', 'deleteTicketQueue')
          resolve(data)
        })
        .catch(error => {
          commit('SET_ERROR', { error, type: 'deleteTicketQueue' })
          // commit('DELETE_TICKET_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', { pending: false, type: 'deleteTicketQueue' })
        })
    })
  },
}

const mutations = {
  SET_PENDING: (state, { pending, type = 'default' }) => {
    state.pending = { ...state.pending, [type]: pending }
  },
  SET_ERROR: (state, { error, type }) => {
    state.errors = { ...state.errors, [type]: error }
  },
  CLEAR_ERROR: (state, type) => {
    state.errors = { ...state.errors, [type]: null }
  },
  CLEAR_TICKET_QUEUES: state => {
    state.tickets = []
  },
  GET_TICKET_QUEUES_SUCCESS(state, { pagination, ticketQueues }) {
    state.ticketQueues = ticketQueues
    state.pagination = pagination
  },
  DELETE_TICKET_QUEUES_SUCCESS(state) {
    return
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
