import http from './http'

export default {
  checkDomain(domain) {
    return http.post('domains/check/', {
      tld: domain.tld,
      sld: domain.sld,
    })
  },
}
