// Quote Store

import { getField, updateField } from 'vuex-map-fields'
import { cloneDeep, map } from 'lodash'

const quote = {
  amount: 0,
  client_id: null,
  extratext: null,
  quote_nr: null,
  quote_products: [],
  quotedate: null,
  quoterange_id: null,
  quotetime: 14,
  tenant_id: null,
  status: null,
  discount: 0,
  discount_type: 'percent',
  tenant: {
    billing_handle: {},
  },
}

const state = {
  errors: {},
  quote: cloneDeep(quote),
  quotePdf: null,
  quotePresets: [],
  pending: {},
}

const getters = {
  getField,
}

const actions = {
  getQuote({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      const { id } = rootState.route.params

      commit('SET_PENDING', { pending: true, type: 'getQuote' })

      this.api.item
        .getItem('quotes', id)
        .then(({ data }) => {
          commit('GET_QUOTE_SUCCESS', data.quote)
          resolve()
        })
        .catch(error => {
          commit('GET_QUOTE_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', { pending: false, type: 'getQuote' })
        })
    })
  },
  getQuotePdf({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDING', { pending: true, type: 'getQuotePdf' })

      this.api.quote
        .getPdf(id)
        .then(({ data }) => {
          commit('GET_QUOTE_PDF_SUCCESS', data.quote)
          resolve(data.quote)
        })
        .catch(error => {
          commit('GET_QUOTE_PDF_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', { pending: false, type: 'getQuotePdf' })
        })
    })
  },
  getQuotePdfPreview({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDING', { pending: true, type: 'getQuotePdfPreview' })

      this.api.quote
        .getPdfPreview(state.quote)
        .then(({ data }) => {
          commit('GET_QUOTE_PDF_SUCCESS', data.quote)
          resolve()
        })
        .catch(error => {
          commit('GET_QUOTE_PDF_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', { pending: false, type: 'getQuotePdfPreview' })
        })
    })
  },
  getEditQuote({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      const { id } = rootState.route.params

      commit('SET_PENDING', { pending: true, type: 'getEditQuote' })

      this.api.item
        .getEditItem('quotes', id)
        .then(({ data }) => {
          const quote = cloneDeep(data).quote
          quote.quote_products = map(quote.quote_products, p => {
            if (p.artNr) {
              p.isDirty = true
            }
            return p
          })
          commit('GET_QUOTE_SUCCESS', quote)
          commit('GET_QUOTE_PRESETS_SUCCESS', {
            quoteranges: data.quoteranges,
            tenants: data.tenants,
          })
          resolve()
        })
        .catch(error => {
          commit('GET_QUOTE_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', { pending: false, type: 'getEditQuote' })
        })
    })
  },
  getQuotePresets({ commit }) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDING', { pending: true, type: 'getQuotePresets' })

      this.api.item
        .getOptions('quotes')
        .then(({ data }) => {
          commit('GET_QUOTE_PRESETS_SUCCESS', data)
          resolve()
        })
        .catch(error => {
          commit('GET_QUOTE_PRESETS_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', { pending: false, type: 'getQuotePresets' })
        })
    })
  },
  createQuote({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDING', { pending: true, type: 'createQuote' })

      delete state.quote.tenant
      delete state.quote.status

      this.api.item
        .addItem('quotes', state.quote)
        .then(({ data }) => {
          if (data && data.errors) {
            commit('ADD_QUOTE_FAIL', data.errors)
            resolve()
          }
          commit('ADD_QUOTE_SUCCESS')
          resolve(data)
        })
        .catch(error => {
          commit('ADD_QUOTE_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', { pending: false, type: 'createQuote' })
        })
    })
  },
  editQuote({ commit, state }, onlyEdit = true) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDING', { pending: true, type: 'editQuote' })

      delete state.quote.client
      delete state.quote.tenant

      this.api.item
        .editItem('quotes', state.quote)
        .then(({ data }) => {
          if (data && data.errors) {
            commit('EDIT_QUOTE_FAIL', data.errors)
            resolve()
          }

          if (onlyEdit) {
            commit('EDIT_QUOTE_SUCCESS')
          } else {
            const quote = cloneDeep(data).quote
            quote.quote_products = map(quote.quote_products, p => {
              if (p.artNr) {
                p.isDirty = true
              }
              return p
            })
            commit('EDIT_QUOTE_SUCCESS', quote)
          }

          resolve(data)
        })
        .catch(error => {
          commit('EDIT_QUOTE_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', { pending: false, type: 'editQuote' })
        })
    })
  },
  addQuoteProduct({ commit }) {
    commit('ADD_QUOTE_PRODUCT')
  },
  removeQuoteProduct({ commit }, index) {
    commit('REMOVE_QUOTE_PRODUCT', index)
  },
  clearQuote({ commit }) {
    commit('CLEAR_QUOTE')
  },
}

const mutations = {
  SET_PENDING: (state, { pending, type = 'default' }) => {
    state.pending = { ...state.pending, [type]: pending }
  },
  CLEAR_QUOTE(state) {
    state.quote = cloneDeep(quote)
    state.quotePdf = null
    state.errors = {}
  },
  ADD_QUOTE_PRODUCT(state) {
    state.quote.quote_products.push({
      artNr: null,
      quantity: null,
      amount: null,
      title: null,
      description: null,
      tax: 19,
      discount: null,
      discount_type: null,
      isDirty: false,
      sort_nr: state.quote.quote_products.length + 1,
    })
  },
  UPDATE_ALL_QUOTE_PRODUCTS(state, products) {
    const allProducts = products.map((product, index) => ({
      ...product,
      sort_nr: index + 1,
    }))
    state.quote.quote_products = [...allProducts]
  },
  REMOVE_QUOTE_PRODUCT(state, index) {
    state.quote.quote_products.splice(index, 1)
    this.commit('quote/UPDATE_ALL_QUOTE_PRODUCTS', state.quote.quote_products)
  },
  GET_QUOTE_SUCCESS(state, data) {
    state.errors = {}
    state.quote = data
  },
  GET_QUOTE_FAIL(state, errors) {
    state.errors = errors
  },
  ADD_QUOTE_SUCCESS(state) {
    state.errors = {}
  },
  ADD_QUOTE_FAIL(state, errors) {
    state.errors = errors
  },
  GET_QUOTE_PRESETS_SUCCESS(state, data) {
    state.quotePresets = data
    state.errors = {}
  },
  GET_QUOTE_PRESETS_FAIL(state, errors) {
    state.errors = errors
  },
  EDIT_QUOTE_SUCCESS(state, quote = false) {
    state.errors = {}
    if (quote) {
      state.quote = quote
    }
  },
  EDIT_QUOTE_FAIL(state, errors) {
    state.errors = errors
  },
  GET_QUOTE_PDF_SUCCESS(state, data) {
    state.errors = {}
    state.quotePdf = data
  },
  GET_QUOTE_PDF_FAIL(state, errors) {
    state.errors = errors
  },
  updateField,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
