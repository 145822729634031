import Vue from 'vue'
import { i18n } from './i18n'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

const extraRules = {
  decimal: {
    validate: (value, { decimals = '*', separator = '.' } = {}) => {
      if (value === null || value === undefined || value === '') {
        return {
          valid: false,
        }
      }
      if (Number(decimals) === 0) {
        return {
          valid: /^-?\d*$/.test(value),
        }
      }
      const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`
      const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`)
      return {
        valid: regex.test(value),
      }
    },
    message: (_, values) => {
      return i18n.t('validations.decimal', values)
    },
  },
  timeMin: {
    validate: (value, minTime) => {
      if (value === null || value === undefined || value === '') {
        return {
          valid: false,
        }
      }

      const timeParsed = value.replace(':', '')
      const minTimeParsed = minTime[0]?.replace(':', '') || 0

      return {
        valid: timeParsed > minTimeParsed,
      }
    },
    message: (_, values) => {
      return i18n.t('validations.timeMin', values)
    },
  },
}

Object.keys(extraRules).forEach(rule => {
  extend(rule, extraRules[rule])
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
