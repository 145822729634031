import { getField, updateField } from 'vuex-map-fields'
import { cloneDeep, map } from 'lodash'

const subscription = {
  // required
  name: null,
  client_id: null,
  subscription_products: [],
  startdate: null,
  cancelationperiod: null,
  period: null,
  invoicerange_id: null,
  tenant_id: null,
  // optional / edit view
  discount: 0,
  discount_type: 'percent',
  canterminate: null,
  enddate: null,
  synclock: 0,
  active: 1,
  // read-only
  synced: 0,
  created: null,
  lastrenewed: null,
  nextrenew: null,
  subscription_transactions: [],
}

const state = {
  errors: {},
  foundProductByArtNr: [],
  foundProductByTitle: [],
  subscription: cloneDeep(subscription),
  subscriptionPresets: {},
  pending: false,
  pendingPresets: false,
  pendingSave: false,
  pendingSearchByArtNR: false,
  pendingSearchByTitle: false,
}

const getters = {
  getField,
}

const actions = {
  getSubscription({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta
      const { id } = rootState.route.params

      commit('SET_PENDING', true)

      this.api.item
        .getItem(type, id)
        .then(({ data }) => {
          commit('GET_SUBSCRIPTION_SUCCESS', data.subscription)
          resolve()
        })
        .catch(error => {
          commit('GET_SUBSCRIPTION_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  getEditSubscription({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta
      const { id } = rootState.route.params

      commit('SET_PENDING', true)

      this.api.item
        .getEditItem(type, id)
        .then(({ data }) => {
          const subscription = cloneDeep(data).subscription
          subscription.subscription_products = map(
            subscription.subscription_products,
            p => {
              if (p.artNr) {
                p.isDirty = true
              }
              return p
            },
          )
          commit('GET_SUBSCRIPTION_SUCCESS', subscription)
          resolve()
        })
        .catch(error => {
          commit('GET_SUBSCRIPTION_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  getSubscriptionPresets({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDINGPRESETS', false)
      const { type } = rootState.route.meta

      this.api.item
        .getOptions(type)
        .then(({ data }) => {
          commit('GET_SUBSCRIPTION_PRESETS_SUCCESS', data)
          resolve()
        })
        .catch(error => {
          commit('GET_SUBSCRIPTION_PRESETS_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDINGPRESETS', false)
        })
    })
  },
  createSubscription({ commit, state, rootState }) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta

      commit('SET_PENDING', true)

      this.api.item
        .addItem(type, state.subscription)
        .then(({ data }) => {
          if (data && data.errors) {
            commit('ADD_SUBSCRIPTION_FAIL', data.errors)
            return
          }
          commit('ADD_SUBSCRIPTION_SUCCESS')
          resolve(data)
        })
        .catch(error => {
          commit('ADD_SUBSCRIPTION_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  editSubscription({ commit, state, rootState }) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta

      commit('SET_PENDINGSAVE', true)

      this.api.item
        .editItem(type, state.subscription)
        .then(({ data }) => {
          if (data && data.errors) {
            commit('EDIT_SUBSCRIPTION_SUCCESS', data.errors)
            return
          }
          commit('EDIT_SUBSCRIPTION_SUCCESS', data.subscription)
          resolve(data)
        })
        .catch(error => {
          commit('EDIT_SUBSCRIPTION_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDINGSAVE', false)
        })
    })
  },
  searchProductByArtNr({ commit }, searchArtNr) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDINGSEARCHBYARTNR', true)

      this.api.invoice
        .searchProductByArtNr(searchArtNr)
        .then(({ data }) => {
          commit('SEARCH_PRODUCT_ART_NR_SUCCESS', data)
          resolve(data)
        })
        .catch(error => {
          commit('SEARCH_PRODUCT_ART_NR_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDINGSEARCHBYARTNR', false)
        })
    })
  },
  searchProductByTitle({ commit }, searchTitle) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDINGSEARCHBYTITLE', true)

      this.api.invoice
        .searchProductByTitle(searchTitle)
        .then(({ data }) => {
          commit('SEARCH_PRODUCT_TITLE_SUCCESS', data)
          resolve(data)
        })
        .catch(error => {
          commit('SEARCH_PRODUCT_TITLE_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDINGSEARCHBYTITLE', false)
        })
    })
  },
  addSubscriptionProduct({ commit }) {
    commit('ADD_SUBSCRIPTION_PRODUCT')
  },
  removeSubscriptionProduct({ commit }, index) {
    commit('REMOVE_SUBSCRIPTION_PRODUCT', index)
  },
  clearSubscription({ commit }) {
    commit('CLEAR_SUBSCRIPTION')
  },
  clearProductSearchByArtNr({ commit }) {
    commit('CLEAR_PRODUCT_SEARCH_BY_ART_NR')
  },
  clearProductSearchByTitle({ commit }) {
    commit('CLEAR_PRODUCT_SEARCH_BY_TITLE')
  },
}

const mutations = {
  CLEAR_SUBSCRIPTION(state) {
    state.subscription = cloneDeep(subscription)
    state.subscriptionPresets = {}
    state.errors = {}
  },
  CLEAR_PRODUCT_SEARCH_BY_ART_NR(state) {
    state.foundProductByArtNr = []
  },
  CLEAR_PRODUCT_SEARCH_BY_TITLE(state) {
    state.foundProductByTitle = []
  },
  ADD_SUBSCRIPTION_PRODUCT(state) {
    state.subscription.subscription_products.push({
      artNr: null,
      name: null,
      description: null,
      price: null,
      tax: null,
      discount: null,
      discount_type: null,
      isDirty: false,
      sort_nr: state.subscription.subscription_products.length + 1,
    })
  },
  UPDATE_ALL_SUBSCRIPTION_PRODUCTS(state, products) {
    const allProducts = products.map((product, index) => ({
      ...product,
      sort_nr: index + 1,
    }))
    state.subscription.subscription_products = [...allProducts]
  },
  REMOVE_SUBSCRIPTION_PRODUCT(state, index) {
    state.subscription.subscription_products.splice(index, 1)
    this.commit('subscription/UPDATE_ALL_SUBSCRIPTION_PRODUCTS', state.subscription.subscription_products)
  },
  GET_SUBSCRIPTION_SUCCESS(state, data) {
    state.errors = {}
    state.subscription = data
  },
  GET_SUBSCRIPTION_FAIL(state, errors) {
    state.errors = errors
  },
  ADD_SUBSCRIPTION_SUCCESS(state) {
    state.errors = {}
  },
  ADD_SUBSCRIPTION_FAIL(state, errors) {
    state.errors = errors
  },
  GET_SUBSCRIPTION_PRESETS_SUCCESS(state, data) {
    state.subscriptionPresets = data
    state.errors = {}
  },
  GET_SUBSCRIPTION_PRESETS_FAIL(state, errors) {
    state.errors = errors
  },
  EDIT_SUBSCRIPTION_SUCCESS(state, data) {
    state.errors = {}
    state.subscription = data
  },
  EDIT_SUBSCRIPTION_FAIL(state, errors) {
    state.errors = errors
  },
  SEARCH_PRODUCT_ART_NR_SUCCESS(state, data) {
    if (data !== null) {
      state.foundProductByArtNr = data
    } else {
      state.foundProductByArtNr = []
    }
  },
  SEARCH_PRODUCT_ART_NR_FAIL(state, errors) {
    state.errors = errors
  },
  SEARCH_PRODUCT_TITLE_SUCCESS(state, data) {
    if (data !== null) {
      state.foundProductByTitle = data
    } else {
      state.foundProductByTitle = []
    }
  },
  SEARCH_PRODUCT_TITLE_FAIL(state, errors) {
    state.errors = errors
  },
  updateField,
}

const object_types = [
  'pending',
  'pendingSave',
  'pendingPresets',
  'pendingSearchByArtNR',
  'pendingSearchByTitle',
]

object_types.forEach(object_type => {
  mutations['SET_' + object_type.toUpperCase()] = (state, payload) => {
    state[object_type] = payload
  }
})

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
