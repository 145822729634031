import http from './http'

export default {
  takeTicket(ticketId) {
    return http.put(`tickets/take/${ticketId}`)
  },
  getClientData(clientId) {
    return http.get(`tickets/getClientData/${clientId}`)
  },
}
