<template>
  <Login />
</template>

<script>
import { defineComponent, onBeforeUnmount } from '@vue/composition-api'

import Login from '@/components/auth/login'

const body = document.body

export default defineComponent({
  name: 'ViewLogin',
  components: {
    Login,
  },
  setup(props, { root: { $store } }) {
    body.classList.add('login-page')

    onBeforeUnmount(_ => {
      body.classList.remove('login-page')
    })
  },
}) //
</script>
